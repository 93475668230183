<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card title="Kayıt Düzenle">
        <repair-form />
      </b-card>
      <b-card no-body>
        <b-card-header>
          <b-card-title>Dokümanlar</b-card-title>
        </b-card-header>
        <document-list-table
          :table-data="documentList"
          :delete-data="deleteDocument"
        />
      </b-card>
      <save-button-line :action-methods="saveData" />
    </validation-observer>
  </div>

</template>

<script>

import { BCard, BCardHeader, BCardTitle } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import SaveButtonLine from '@/views/Damage_tracking/elements/SaveButtonLine.vue'
import RepairForm from '@/views/Damage_tracking/Repair/components/RepairForm.vue'
import DocumentListTable from '@/views/Damage_tracking/document/DocumentListTable.vue'

export default {
  name: 'EditRepair',
  components: {
    DocumentListTable,
    BCardTitle,
    BCardHeader,
    RepairForm,
    ValidationObserver,
    SaveButtonLine,
    BCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['damageRepair/getData']
    },
    saveStatus() {
      return this.$store.getters['damageRepair/saveDataStatus']
    },
    documentList() {
      return this.$store.getters['damageTrackingDocument/getDataList']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$router.push(`/damage-tracking/view/${this.dataItem.id_com_damage_tracking}`)
        this.$store.commit('damageRepair/SET_DATA_RESET')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.dataItem.submitStatus = false
    },
  },
  created() {
    this.getData()
    this.getDocumentList()
  },
  methods: {
    getData() {
      return this.$store.dispatch('damageRepair/dataItem', this.$route.params.id)
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('damageRepair/saveData', this.dataItem)
        }
      })
    },
    getDocumentList() {
      return this.$store.dispatch('damageTrackingDocument/dataList', {
        where: {
          'com_damage_tracking_documents.id_com_damage_car_repair': this.$route.params.id,
        },
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    deleteDocument(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('damageTrackingDocument/removeData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$store.dispatch('damageTrackingDocument/dataList', {
                  where: {
                    'com_damage_tracking_documents.id_com_damage_car_repair': this.$route.params.id,
                  },
                })
              }
            })
        }
      })
    },
  },

}
</script>
